.desktop-text {
    display: block;
}

.mobile-text {
    display: none;
}

@media (max-width: 577px) {

    h1 {
        font-size: 0.70rem;
    }

    .desktop-text {
        display: none;
    }

    .mobile-text {
        display: block;
    }
  }

    @media (min-width: 578px) and (max-width: 1376px) {

    h1 {
        font-size: 1.25rem;
    }
}

@media (min-width: 1377px) {

    h1 {
        font-size: 1.25rem;
    }
}